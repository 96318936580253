.modal-confirm-hook .ant-modal-content {
    padding: 0px !important;
    justify-content: center;
    border-radius: 14px !important;
    overflow: hidden !important;
}

.modal-confirm-hook .ant-modal-confirm-content {
    width: 100% !important;
}

.modal-confirm-hook .ant-modal-content {
    background-color: transparent !important;
}